import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockFaqItem.module.css';

const FieldMedia = ({ className, media, sizes, options }) => {
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockFaqItem = ({
  blockId,
  className,
  rootClassName,
  mediaClassName,
  textClassName,
  ctaButtonClass,
  title,
  text,
  callToAction,
  media,
  responsiveImageSizes,
  options,
}) => {
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  return (
    <BlockContainer id={blockId} className={classes}>
      <details className={css.accordion}>
        <summary className={css.accordionHeader}>
          <Field data={title} options={options} />
          <span className={css.arrow}></span>
        </summary>
        <div className={css.accordionContent}>
          <FieldMedia media={media} sizes={responsiveImageSizes} className={mediaClassName} options={options} />
          {hasTextComponentFields && (
            <div className={classNames(textClassName, css.text)}>
              <Field data={text} options={options} />
              <Field data={callToAction} className={ctaButtonClass} options={options} />
            </div>
          )}
        </div>
      </details>
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockFaqItem.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockFaqItem.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockFaqItem;